<template>
  <div class="component-content">

    <TopMenu class="mb-12p">
      <template v-slot:button>
        <button class="btn btn-bg-none btn-close" @click="destroy()">
          <img class="icon-16" src="/static/images/icon/back.svg">
        </button>
      </template>

      <template v-slot:page-name>新增用户</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>
    </TopMenu>

    <div class="row flex-1 h-100 overflow-hidden">
      <div class="col-6 h-100">
        <div class="content bg-25 p-2 h-100">
          
          <h3 class="mb-2">基本信息</h3>
          <div class="mb-2">
            <label>登录名</label>
            <input type="text" class="form-control" placeholder="登录名" maxlength="16" v-model="datas.username">
          </div>
          <div class="mb-2">
            <label>真实名称</label>
            <input type="text" class="form-control" placeholder="真实名称" maxlength="16" v-model="datas.nickname">
          </div>

          <div class="flex-list">
            <div style="width:50%;">
              <label>{{ updateOrCreate }}</label>
              <input type="password" class="form-control" :placeholder="updateOrCreate" v-model="datas.password1">
            </div>
            <div style="width:50%;">
              <label>确认{{ updateOrCreate }}</label>
              <input type="password" class="form-control" :placeholder="'确认'+updateOrCreate" v-model="datas.password2">
            </div>
          </div>

        </div>
      </div>

      <div class="col-6 h-100">
        <div class="content bg-25 p-2 h-100">
          <h3 class="mb-2">权限设置</h3>
          <h4>销售权限</h4>
          <div class="row mb-1">
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('101')" @click="setPermissions($event, '101')" :disabled="disabled" /><span class="ml-1">管理销售单列表</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('102')" @click="setPermissions($event, '102')" :disabled="disabled" /><span class="ml-1">新建与编辑销售单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('107')" @click="setPermissions($event, '107')" :disabled="disabled" /><span class="ml-1">新建与编辑退货单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('103')" @click="setPermissions($event, '103')" :disabled="disabled" /><span class="ml-1">删除销售单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('104')" @click="setPermissions($event, '104')" :disabled="disabled" /><span class="ml-1">复制销售单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('105')" @click="setPermissions($event, '105')" :disabled="disabled" /><span class="ml-1">打印销售单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('106')" @click="setPermissions($event, '106')" :disabled="disabled" /><span class="ml-1">导出销售单数据</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('111')" @click="setPermissions($event, '111')" :disabled="disabled" /><span class="ml-1">销售日历</span></label>
            </div>
          </div>

          <h4>进货权限</h4>
          <div class="row mb-1">
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('501')" @click="setPermissions($event, '501')" :disabled="disabled" /><span class="ml-1">管理库存单列表</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('502')" @click="setPermissions($event, '502')" :disabled="disabled" /><span class="ml-1">新建与编辑进货单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('503')" @click="setPermissions($event, '503')" :disabled="disabled" /><span class="ml-1">新建与编辑退货单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('504')" @click="setPermissions($event, '504')" :disabled="disabled" /><span class="ml-1">删除库存单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('505')" @click="setPermissions($event, '505')" :disabled="disabled" /><span class="ml-1">复制库存单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('506')" @click="setPermissions($event, '506')" :disabled="disabled" /><span class="ml-1">导出库存单数据</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('507')" @click="setPermissions($event, '507')" :disabled="disabled" /><span class="ml-1">盘点</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('508')" @click="setPermissions($event, '508')" :disabled="disabled" /><span class="ml-1">库存</span></label>
            </div>
          </div>

          <h4>生产权限</h4>
          <div class="row mb-1">
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('601')" @click="setPermissions($event, '601')" :disabled="disabled" /><span class="ml-1">管理生产单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('602')" @click="setPermissions($event, '602')" :disabled="disabled" /><span class="ml-1">新建与编辑生产单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('604')" @click="setPermissions($event, '604')" :disabled="disabled" /><span class="ml-1">删除生产单</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('606')" @click="setPermissions($event, '606')" :disabled="disabled" /><span class="ml-1">导出生产单数据</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('607')" @click="setPermissions($event, '607')" :disabled="disabled" /><span class="ml-1">产品配料</span></label>
            </div>
          </div>

          <h4>货物权限</h4>
          <div class="row mb-1">
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('201')" @click="setPermissions($event, '201')" :disabled="disabled" /><span class="ml-1">货物列表</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('202')" @click="setPermissions($event, '202')" :disabled="disabled" /><span class="ml-1">新建与编辑货物</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('203')" @click="setPermissions($event, '203')" :disabled="disabled" /><span class="ml-1">删除货物</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('204')" @click="setPermissions($event, '204')" :disabled="disabled" /><span class="ml-1">复制货物</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('205')" @click="setPermissions($event, '205')" :disabled="disabled" /><span class="ml-1">导出货物数据</span></label>
            </div>
          </div>

          <h4>客户权限</h4>
          <div class="row mb-1">
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('301')" @click="setPermissions($event, '301')" :disabled="disabled" /><span class="ml-1">客户列表</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('302')" @click="setPermissions($event, '302')" :disabled="disabled" /><span class="ml-1">新建与编辑客户</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('303')" @click="setPermissions($event, '303')" :disabled="disabled" /><span class="ml-1">删除客户</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('304')" @click="setPermissions($event, '304')" :disabled="disabled" /><span class="ml-1">复制客户</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('305')" @click="setPermissions($event, '305')" :disabled="disabled" /><span class="ml-1">导出产品数据</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('306')" @click="setPermissions($event, '306')" :disabled="disabled" /><span class="ml-1">编辑属性</span></label>
            </div>
          </div>

          <h4>用户权限</h4>
          <div class="row mb-1">
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('401')" @click="setPermissions($event, '401')" :disabled="disabled" /><span class="ml-1">用户列表</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('402')" @click="setPermissions($event, '402')" :disabled="disabled" /><span class="ml-1">新建与编辑用户</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('403')" @click="setPermissions($event, '403')" :disabled="disabled" /><span class="ml-1">删除用户</span></label>
            </div>
            <div class="col-4">
              <label><input type="checkbox" :checked="datas.permissions.includes('404')" @click="setPermissions($event, '404')" :disabled="disabled" /><span class="ml-1">导出产品数据</span></label>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'

export default {
  name: 'UserEdit',

  data() {
    return {
      loading: false,
      datas: {
        id: '',
        username: '',
        nickname: '',
        password1: '',
        password2: '',
        permissions: [],
      },
      allowAttack: false,
      updateOrCreate: '',
      disabled: false
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    if (this.$route.params.id) {
      this.updateData()
      console.log('修改')
    }

    this.updateOrCreate = this.datas.id ? '密码' : '初始密码'
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
  },

  watch: {
    datas: function() {
      this.updateOrCreate = this.datas.id ? '密码' : '初始密码'
    }
  },

  methods: {

    //更新订单
    updateData() {
      this.$axios.post('/api/user/get', {
        id: this.$route.params.id
      },{
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            res.data.permissions = res.data.permissions.split(',')
            res.data.password1 = ''
            res.data.password2 = ''
            if (res.data.level <= 2) {
              this.disabled = true
            }
            this.datas = res.data
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    destroy() {
      this.$router.push({name: 'UserList'})
    },

    // 勾选设置权限
    setPermissions(e, number) {
      if (e.target.checked) {
        if (!this.datas.permissions.includes(number)) {
          this.datas.permissions.push(number)
        }
      } else {
        this.datas.permissions.indexOf
        for (var i = 0; i < this.datas.permissions.length; i++) {
          let index = this.datas.permissions.indexOf(number)
          if (index > -1) {
            this.datas.permissions.splice(index, 1);
          }
        }
      }

      console.log(this.datas.permissions)
    },

    save() {
      let _this = this
      this.allowAttack = true
      this.datas.permissions = this.datas.permissions.toString()

      try {

        if (!this.datas.username) throw '请填写登录名'
        if(this.datas.username && !this.datas.username.match(/^[a-z0-9-_]{4,16}$/)) throw '登录名只支持英文、数字，长度最少4位，最多16位'
        if (!this.datas.nickname) throw '请填写真实名'
        if (!this.datas.id || this.datas.password1 || this.datas.password2) {
          if (this.datas.password1.length < 8) throw '请填写' + this.updateOrCreate + '，长度不少于8位'
          if (this.datas.password2.length < 8) throw '请填写确认' + this.updateOrCreate + '，长度不少于8位'

          if (this.datas.password1 != this.datas.password2) throw '确认' + this.updateOrCreate + '不相符'
        }
        

        _this.$axios.post('/api/user/save',
          {
            user: _this.datas
          },
          {
            headers: {
              token: JSON.parse(sessionStorage.Authorization).token
            }
          }
        )
        .then(res => {
          _this.allowAttack = false

          if (res.status == 200) {
            console.log(res.data)
            if (res.data.success) {
              res.data.data.password1 = ''
              res.data.data.password2 = ''
              this.datas = res.data.data
              this.$toast.success(res.data.message)
            } else {
              this.$toast.error(res.data.message)
            }
            
          }
        })
        .catch(error => {
          _this.allowAttack = false
          console.error('error:', error)
        })
      } catch(error) {
        console.error(error)
        _this.allowAttack = false
        this.$toast.error(error)
      }
    }

  },
}
</script>

<style scoped>

label {
  color: #bababa;
}
  
.flex-list-header .delete {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flex-list-header:hover .delete {
  display: block;
}
.btn-create {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding: 0;
  flex-shrink: 0;
  background-color: #004bff;
}

.vc-container.vc-is-dark {
  color: #fff;
  background-color: #383838;
  border: none;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-4 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6:first-child {
  padding-left: 0;
}
.col-6:last-child {
  padding-right: 0;
}
  
</style>
